<template>
    <div>
        <Header :id="id"></Header>
        <a-carousel autoplay>
          <div v-for="(item,index) in banners" :key="index" class="lbanner">
            <img :src="item.thumb" alt="">
            <video :src="item.mediaUrl" controls="controls" class="video"></video>
          </div>
        </a-carousel>
        <div class="lvideo">
          <div class="lvideo2">
            <div class="ltitle">{{ $t('live') }}</div>
            <div class="videolist">
              <div class="video-item" v-for="(item,index) in videos" :key="index">
                <video :src="item.url" controls="controls" class="video"></video>
                <img :src="item.thumb" alt="">
                <!-- <div class="video-title">{{item.title}}</div> -->
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "Live",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
        id: this.$route.params.id,
        banners: [],
        videos: [],
        token: localStorage.token
    }
  },
  mounted () {
    if (this.token == '') {
      this.$layer.msg('请先登录');
      this.$router.go(-1)
    }
    this.getVideoList()
  },
  methods: {
    // 获取视频列表
    getVideoList () {
      this.$common.fetchList('/exhibition/video/lists?exhibitionId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/live.less';
</style>